
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import Head from 'next/head';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import style from 'styles/export-variables.module.scss';
import { frFR, enUS } from '@mui/material/locale'

import MobileTopBar from 'components/mobile-top-bar';
import 'styles/global.scss';
import Sidebar from 'components/sidebar'
import { useRouter } from 'next/router';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Snackbar, Typography } from '@mui/material';
import { RouteGuard } from 'components/route-guard';
import { useStateWithLocalStorage } from 'src/hooks';
import Footer from 'components/footer';
import { LangName } from 'lib/utils';
import useTranslation from 'next-translate/useTranslation';
import Chatra from '@chatra/chatra';
import React from 'react';
import { getDocumentCookie, setDocumentCookie } from 'src/helpers';
import Script from 'next/script';
import { GetUser } from 'lib/me';
import Link from 'next/link';
import getConfig from 'next/config';

const __Page_Next_Translate__ = function App({ Component, pageProps }) {
  const router = useRouter();
  const { t, lang } = useTranslation('common');
  const [showLoading, setShowLoading] = useState(true);
  const [sidebarCollapsedString, setSidebarCollapsedString] = useStateWithLocalStorage('sidebar-isCollapsed', 'unset');
  const [mobileSidebarVisible, setMobileSidebarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState();
  const [showAccountInactiveDialog, setShowAccountInactiveDialog] = useState(false);
  const [userTimeZone, setUserTimeZone] = useState('GMT');
  const [userDefaultLang, setUserDefaultLang] = useStateWithLocalStorage('default_lang', lang);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const themeLocale = (lang == 'fr') ? frFR : enUS;

  const { publicRuntimeConfig } = getConfig();

  // Maintenance vars
  const currDate = new Date();
  const currTime = currDate.getTime();
  const maintenanceDate = new Date(publicRuntimeConfig.SCHEDULED_MAINTENANCE);

  const muiTheme = createTheme(
    {
      palette: {
        primary: {
          // light: will be calculated from palette.primary.main,
          main: style.colorPrimary,
          // dark: will be calculated from palette.primary.main,
          contrastText: '#ffffff',
        },
        secondary: {
          //light: '#0066ff',
          main: style.colorSecondary,
          // dark: will be calculated from palette.secondary.main,
          //contrastText: '#ffcc00',
        },
        text: {
          primary: style.colorText,
        },
      },
      typography: {
        fontFamily: style.fontStyleText,
        h1: {
          fontSize: '3rem',
        },
        h2: {
          fontSize: '2rem',
        },
        h3: {
          fontSize: '1.5rem',
        },
        h4: {
          fontSize: '1.2rem',
          fontWeight: 500,
          color: style.colorTextLight,
        },
        h5: {
          fontSize: '1.2rem',
        },
        h6: {
          fontSize: '1.1rem',
        },
      },
    },
    themeLocale
  );

  useEffect(() => {
    const pathsWithoutSidebar = ['/login', '/payment', '/setup', '/stripe_3ds']
    const path = router.asPath.split('?')[0];
    setShowSidebar(!pathsWithoutSidebar.includes(path));
  }, [router.asPath]);

  const setSidebarCollapsed = (collapsed) => {
    setSidebarCollapsedString((collapsed) ? '1' : '');
  };

  // Used to force re-render of permission-based components in sidebar when plan changed
  const [updatePermissionsCounter, setUpdatePermissionsCounter] = useState(0);
  const updatePermissions = () => {
    setUpdatePermissionsCounter(updatePermissionsCounter + 1);
  }

  // Automatically collapse the sidebar on edit pages if it hasn't been done manually
  const url = router.asPath;
  const collapseSidebarOnPaths = ['/recipes/', '/ingredients/', '/labels/', '/login', '/reset/', '/ingredients-database'];
  const sidebarDefaultCollapsed = collapseSidebarOnPaths.some(path => { return url.indexOf(path) >= 0; });

  const isSidebarCollapsed = (sidebarCollapsedString == 'unset') ? sidebarDefaultCollapsed : Boolean(sidebarCollapsedString);

  // App wrapper needs to set spacing for sidebar
  const appWrapperCn = classNames(
    'appWrapper',
    {
      'sidebarCollapsed': isSidebarCollapsed,
      'sidebarHidden': !showSidebar,
    }
  );

  // Loading indicator when changing routes
  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setShowLoading(true);
    });
    router.events.on('routeChangeComplete', (url) => {
      setShowLoading(false);
    });
    router.events.on('routeChangeError', () => {
      setShowLoading(false);
    });

    // Initial page load
    setShowLoading(false);

    // Chatra
    Chatra('init', {
      ID: '49yAPowXoE7td4yhf',
      setup: {
        language: lang,
        startHidden: true,
      },
    });

    // User timezone
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (tz) {
      setUserTimeZone(tz);
    }

    // Fetch user lang on initial load if logged in
    // In case the user bookmarked the app with a url in the other lang
    // If the user is not logged in, the lang will be set on login anyway
    const session_id = getDocumentCookie('session_id', null);
    if (session_id != null) {
      GetUser({ sessionId: session_id }).then(response => {
        setUserDefaultLang(response.default_lang);
      });
    }

    // check if admin from cookies - not super safe but just to circumvent the maintenance
    setIsAdmin(getDocumentCookie('tenant_role', null) == 'admin');
  }, []);

  useEffect(() => {
    // Redirect when user lang is different from current lang
    if (userDefaultLang != lang && ['en', 'fr'].includes(userDefaultLang)) {
      const { pathname, asPath, query } = router;
      router.push({ pathname, query }, asPath, { locale: userDefaultLang });
    }
  }, [userDefaultLang, lang, router.asPath]);

  // Response handler that displays snackbars
  const responseHandlerSnackbar = (response, data, isTimeout) => {
    // Timeout
    if (isTimeout) {
      setSnackbarMessage(t('timeout_error_snackbar'));
      setSnackbarSeverity('error');
    }
    else if (typeof (response) != 'object') {
      return;
    }
    else if (response == null || !response.ok) {
      let message = '';
      message = LangName(data, lang, 'message') ?? false;

      if (!message) {
        message = response.statusText;
      }

      setSnackbarMessage(message);
      setSnackbarSeverity('error');
    }
    else {
      setSnackbarMessage(false);
      setSnackbarSeverity('success');
    }

    if (data.code) {
      // 1101 - Unauthorized
      // Means the user is logged out, redirect to login page
      if (data.code == 1101 && typeof router != 'undefined') {
        setDocumentCookie('session_id', null);
        router.push('login');
      }
      // Forbidden - show dialog
      else if (data.code == 1007) {
        setShowAccountInactiveDialog(true);
      }
    }

  };

  const loadingIndicator = (showLoading) ? (<>
    <LinearProgress sx={{ position: 'fixed', width: '100%' }} />
  </>) : '';

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <Head>
            <title>Labelify</title>
            <link rel="icon" href="/favicon.png" />
          </Head>

          {/*-- Google Tag Manager --*/}
          <Script async={true} src="https://www.googletagmanager.com/gtag/js?id=UA-125412363-1" />
          <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            
              gtag('config', 'UA-125412363-1');`
            }}
          />

          {loadingIndicator}

          {showSidebar &&
            <>
              <MobileTopBar onMenuClick={() => { setMobileSidebarVisible(!mobileSidebarVisible); }}></MobileTopBar>

              <Sidebar
                collapsed={isSidebarCollapsed}
                mobileVisible={mobileSidebarVisible}
                setMobileVisible={setMobileSidebarVisible}
                setCollapsed={setSidebarCollapsed}
                updatePermissions={updatePermissions}
                updatePermissionsCounter={updatePermissionsCounter}
                responseHandler={responseHandlerSnackbar}
                setUserDefaultLang={setUserDefaultLang}
              />
            </>
          }

          <div className={appWrapperCn}>
            {(publicRuntimeConfig.SCHEDULED_MAINTENANCE != 0 && currTime < publicRuntimeConfig.SCHEDULED_MAINTENANCE && <>
              <Box mb={2}>
                <Alert severity="warning">{t('common:planned_maintenance_on')} <b>{maintenanceDate.toLocaleString(lang + '-CA', {
                  timeZone: userTimeZone,
                  dateStyle: 'long',
                  timeStyle: 'short',
                })}</b>.</Alert>
              </Box>
            </>)}

            {publicRuntimeConfig.SCHEDULED_MAINTENANCE != 0 && currTime >= publicRuntimeConfig.SCHEDULED_MAINTENANCE &&
              !isAdmin ? <>
              <Typography variant="h1" mb={2}>{t('common:ongoing_maintenance')}</Typography>
              <Typography variant="body1">{t('common:ongoing_maintenance_desc')}</Typography>
            </>
              :
              <>
                <RouteGuard>
                  <Component
                    {...pageProps}
                    key={url}
                    userDefaultLang={userDefaultLang}
                    setUserDefaultLang={setUserDefaultLang}
                    responseHandlerSnackbar={responseHandlerSnackbar}
                    style="height: 100%;"
                    updatePermissions={updatePermissions}
                  />
                </RouteGuard>
              </>
            }

            <Footer />
          </div>

          <Snackbar
            open={!!snackbarMessage}
            autoHideDuration={10000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={(e, reason) => {
              if (reason != 'clickaway') {
                setSnackbarMessage(false);
              }
            }}
          >
            <Alert
              onClose={() => {
                setSnackbarMessage(false);
              }}
              severity={snackbarSeverity}
              sx={{ width: '100%' }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>

          <Dialog
            open={showAccountInactiveDialog}
            onClose={() => { setShowAccountInactiveDialog(false); }}
          >
            <DialogTitle>{t('account_inactive')}</DialogTitle>
            <DialogContent>{t('account_inactive_desc')}</DialogContent>
            <DialogActions>

              <Link href="/billing">
                <Button
                  onClick={() => { setShowAccountInactiveDialog(false); }}
                  variant="contained"
                >{t('billing_page')}</Button>
              </Link>
              <Button
                onClick={() => { setShowAccountInactiveDialog(false); }}
              >{t('cancel')}</Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  