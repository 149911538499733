import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/icon/icon.module.scss'
import classNames from 'classnames';

/**
 * Icons:
 * https://fonts.google.com/icons
 * https://jossef.github.io/material-design-icons-iconfont/
 */

function Icon(props) {
  const cn = classNames(
    styles.icon,
    props.className,
    {
      [styles.ellipsis]: props.ellipsis,
    },
  );

  return (
    <span className={cn} style={{ fontSize: props.size, color: props.color }}>{props.name}</span>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  ellipsis: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
}

Icon.defaultProps = {
  name: '',
  className: '',
  ellipsis: false,
  size: 24,
  color: '',
}

export default Icon;